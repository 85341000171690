import React, { useState } from "react";
import {
  HStack,
  VStack,
  Icon,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  ListItem,
  List,
  Img,
  Tooltip,
} from "@chakra-ui/react";
import {
  MdOutlineSettings,
  MdOutlineViewAgenda,
  MdOutlinePersonAddAlt,
  MdOutlineLibraryBooks,
  MdOutlinePublic,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import LogoExpandido from "../../../assets/Brent-Marca-con-bajada-CMYK.png";
import { ReactComponent as Cargas } from "../../../assets/iconos/Sand-NUEVO.svg";
import { useSelector } from "react-redux";

const Navigate = (props) => {
  const { mobile, expanded, setExpanded } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSelector((state) => state.user);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <VStack
      w={expanded ? "250px" : "55px"}
      h="100vh"
      justifyContent="space-between"
      transition="width 1s"
      overflow="hidden"
    >
      <Box w="100%">
        {mobile && (
          <Box h="65px" px={3} py={1} onClick={() => setExpanded(!expanded)}>
            <Box w="186px" h="70px">
              <Img src={LogoExpandido} alt="" />
            </Box>
          </Box>
        )}
        {!mobile && (
          <Box w="166px" h="50px">
            <Img src={LogoExpandido} alt="" />
          </Box>
        )}
        <VStack cursor="pointer">
          <Accordion w="100%">
            {(user.idRol === "admin" ||
              user.idRol === "usuarioBrent" ||
              user.idRol === "coorOp" ||
              user.idRol === "gerencia" ||
              user.idRol === "userMantenimiento" ||
              user.idRol === "cliente" ||
              user.idRol === "userHS" ||
              user.idRol === "userFacturacion" ||
              user.idRol === "userOperaciones") && (
              <AccordionItem>
                <AccordionButton
                  onClick={() => {
                    navigate("/dashboard");
                    setExpanded(false);
                  }}
                  color={location.pathname === "/dashboard" && "brand.naranja"}
                  _expanded={{
                    borderLeftWidth: "5px",
                    borderLeftColor: "brand.naranja",
                  }}
                  borderLeftWidth={location.pathname === "/dashboard" && "5px"}
                  borderLeftColor={
                    location.pathname === "/dashboard" && "brand.naranja"
                  }
                >
                  <Tooltip label={"Dashboard"} isDisabled={expanded}>
                    <HStack
                      spacing={3}
                      pl={location.pathname === "/dashboard" ? -1 : 1}
                    >
                      <Icon
                        as={MdOutlineViewAgenda}
                        color="gray.400"
                        fontSize={"24px"}
                        transform="rotate(90deg)"
                      />
                      {expanded && <Text>Dashboard</Text>}
                    </HStack>
                  </Tooltip>
                </AccordionButton>
              </AccordionItem>
            )}
            {(user.idRol === "admin" ||
              user.idRol === "usuarioBrent" ||
              user.idRol === "coorOp" ||
              user.idRol === "userHS" ||
              user.idRol === "gerencia" ||
              user.idRol === "userMantenimiento" ||
              user.idRol === "userFacturacion" ||
              user.idRol === "userOperaciones") && (
              <AccordionItem>
                <AccordionButton
                  _expanded={{
                    borderLeftWidth: "5px",
                    borderLeftColor: "brand.naranja",
                  }}
                  onClick={() => setExpanded(true)}
                >
                  <HStack spacing={2} pl={1}>
                    <Tooltip label={"Operaciones"} isDisabled={expanded}>
                      <Icon as={Cargas} boxSize={8} ml={"-5px"} />
                    </Tooltip>
                    {expanded && <Text>Operaciones</Text>}
                  </HStack>
                </AccordionButton>
                <AccordionPanel display={expanded ? "block" : "none"}>
                  <List spacing={1}>
                    <ListItem
                      _hover={{ color: "brand.naranja" }}
                      color={
                        location.pathname === "/operaciones/planificacion" &&
                        "brand.naranja"
                      }
                      onClick={() => {
                        navigate("/operaciones/planificacion");
                        setExpanded(false);
                        handleToggle();
                      }}
                    >
                      Planificaciones
                    </ListItem>
                    <ListItem
                      color={
                        location.pathname === "/operaciones/curso" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/operaciones/curso");
                        setExpanded(false);
                      }}
                    >
                      En curso
                    </ListItem>
                    <ListItem
                      color={
                        location.pathname === "/operaciones/historial" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/operaciones/historial");
                        setExpanded(false);
                      }}
                    >
                      Historial
                    </ListItem>
                  </List>
                </AccordionPanel>
              </AccordionItem>
            )}
            {(user.idRol === "admin" ||
              user.idRol === "coorOp" ||
              user.idRol === "userOperaciones" ||
              user.idRol === "userMantenimiento") && (
              <AccordionItem>
                <AccordionButton
                  _expanded={{
                    borderLeftWidth: "5px",
                    borderLeftColor: "brand.naranja",
                  }}
                  onClick={() => setExpanded(true)}
                >
                  <Tooltip label={"Inventario"} isDisabled={expanded}>
                    <HStack spacing={3} pl={1}>
                      <Icon
                        as={MdOutlineSettings}
                        color="gray.400"
                        boxSize={6}
                      />
                      {expanded && <Text>Inventario</Text>}
                    </HStack>
                  </Tooltip>
                </AccordionButton>
                <AccordionPanel display={expanded ? "block" : "none"}>
                  <List spacing={1}>
                    <ListItem
                      color={
                        location.pathname === "/inventario/clientes" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/inventario/clientes");
                        setExpanded(false);
                      }}
                    >
                      Clientes
                    </ListItem>
                    <ListItem
                      color={
                        location.pathname === "/inventario/locacion" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/inventario/locacion");
                        setExpanded(false);
                      }}
                    >
                      Pad/Locación
                    </ListItem>
                    <ListItem
                      color={
                        location.pathname === "/inventario/planta" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/inventario/planta");
                        setExpanded(false);
                      }}
                    >
                      Plantas
                    </ListItem>
                    <ListItem
                      color={
                        location.pathname === "/inventario/transportista" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/inventario/transportista");
                        setExpanded(false);
                      }}
                    >
                      Empresas Transportistas
                    </ListItem>
                    <ListItem
                      color={
                        location.pathname === "/inventario/camiones" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/inventario/camiones");
                        setExpanded(false);
                      }}
                    >
                      Camiones
                    </ListItem>
                    <ListItem
                      color={
                        location.pathname === "/inventario/autoelevadores" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/inventario/autoelevadores");
                        setExpanded(false);
                      }}
                    >
                      Autoelevadores
                    </ListItem>
                    <ListItem
                      color={
                        location.pathname === "/inventario/sander" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/inventario/sander");
                        setExpanded(false);
                      }}
                    >
                      Sander
                    </ListItem>
                    <ListItem
                      color={
                        location.pathname === "/inventario/sandcubes" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/inventario/sandcubes");
                        setExpanded(false);
                      }}
                    >
                      Sandcubes
                    </ListItem>
                    <ListItem
                      color={
                        location.pathname === "/inventario/sandvan" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/inventario/sandvan");
                        setExpanded(false);
                      }}
                    >
                      SandVan
                    </ListItem>
                  </List>
                </AccordionPanel>
              </AccordionItem>
            )}
            {(user.idRol === "admin" || user.idRol === "usuarioIT") && (
              <AccordionItem>
                <AccordionButton
                  onClick={() => {
                    navigate("/usuarios");
                    setExpanded(false);
                  }}
                  color={location.pathname === "/usuarios" && "brand.naranja"}
                  _expanded={{
                    borderLeftWidth: "5px",
                    borderLeftColor: "brand.naranja",
                  }}
                >
                  <Tooltip label={"Usuarios"} isDisabled={expanded}>
                    <HStack
                      spacing={3}
                      pl={location.pathname === "/usuarios" ? -1 : 1}
                    >
                      <Icon
                        as={MdOutlinePersonAddAlt}
                        color="gray.400"
                        boxSize={6}
                      />
                      {expanded && <Text>Usuarios</Text>}
                    </HStack>
                  </Tooltip>
                </AccordionButton>
              </AccordionItem>
            )}
            {(user.idRol === "admin" ||
              user.idRol === "usuarioBrent" ||
              user.idRol === "coorOp" ||
              user.idRol === "gerencia" ||
              user.idRol === "userHS" ||
              user.idRol === "userMantenimiento" ||
              user.idRol === "userFacturacion" ||
              user.idRol === "userOperaciones") && (
              <AccordionItem>
                <AccordionButton
                  _expanded={{
                    borderLeftWidth: "5px",
                    borderLeftColor: "brand.naranja",
                  }}
                  onClick={() => setExpanded(true)}
                >
                  <Tooltip label={"Reportes"} isDisabled={expanded}>
                    <HStack spacing={3} pl={1}>
                      <Icon
                        as={MdOutlineLibraryBooks}
                        color="gray.400"
                        boxSize={6}
                      />
                      {expanded && <Text>Reportes</Text>}
                    </HStack>
                  </Tooltip>
                </AccordionButton>

                <AccordionPanel display={expanded ? "block" : "none"}>
                  <List spacing={1}>
                    {/* <ListItem
                      color={
                        location.pathname === "/reportes/reportetapas" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/reportes/reportetapas");
                        setExpanded(false);
                      }}
                    >
                      Reporte de etapas
                    </ListItem> */}
                    <ListItem
                      color={
                        location.pathname === "/reportes/reporte" &&
                        "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/reportes/reporte");
                        setExpanded(false);
                      }}
                    >
                      Reporte de viajes
                    </ListItem>
                    {(user.idRol === "admin" ||
                      user.idRol === "coorOp" ||
                      user.idRol === "gerencia" ||
                      user.idRol === "userMantenimiento" ||
                      user.idRol === "userOperaciones") && (
                      <ListItem
                        color={
                          location.pathname === "/reportes/revision" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/reportes/revision");
                          setExpanded(false);
                        }}
                      >
                        Revisión entre turnos
                      </ListItem>
                    )}
                    {(user.idRol === "admin" ||
                      user.idRol === "coorOp" ||
                      user.idRol === "gerencia" ||
                      user.idRol === "userMantenimiento" ||
                      user.idRol === "userHS" ||
                      user.idRol === "userOperaciones") && (
                      <ListItem
                        color={
                          location.pathname === "/reportes/registro" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/reportes/registro");
                          setExpanded(false);
                        }}
                      >
                        Registro de eventos
                      </ListItem>
                    )}
                    {/* {(user.idRol === "admin" ||
                      user.idRol === "coorOp" ||
                      user.idRol === "userFacturacion" ||
                      user.idRol === "userHS" ||
                      user.idRol === "gerencia") && (
                      <ListItem
                        color={
                          location.pathname === "/reportes/certificacion" &&
                          "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/reportes/certificacion");
                          setExpanded(false);
                        }}
                      >
                        Certificación camiones
                      </ListItem>
                    )} */}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            )}
            {(user.idRol === "admin" ||
              user.idRol === "usuarioBrent" ||
              user.idRol === "coorOp" ||
              user.idRol === "gerencia" ||
              user.idRol === "userHS" ||
              user.idRol === "userMantenimiento" ||
              user.idRol === "cliente" ||
              user.idRol === "userFacturacion" ||
              user.idRol === "userOperaciones") && (
              <AccordionItem>
                <AccordionButton
                  _expanded={{
                    borderLeftWidth: "5px",
                    borderLeftColor: "brand.naranja",
                  }}
                  onClick={() => setExpanded(true)}
                  color={location.pathname === "/geocercas" && "brand.naranja"}
                  borderLeftWidth={location.pathname === "/geocercas" && "5px"}
                  borderLeftColor={
                    location.pathname === "/geocercas" && "brand.naranja"
                  }
                >
                  <Tooltip label={"Mapas"} isDisabled={expanded}>
                    <HStack spacing={3} pl={1}>
                      <Icon as={MdOutlinePublic} color="gray.400" boxSize={6} />
                      {expanded && <Text>Mapas</Text>}
                    </HStack>
                  </Tooltip>
                </AccordionButton>
                <AccordionPanel display={expanded ? "block" : "none"}>
                  <List spacing={1}>
                    {(user.idRol === "admin" || user.idRol === "coorOp") && (
                      <ListItem
                        color={
                          location.pathname === "/geocercas" && "brand.naranja"
                        }
                        _hover={{ color: "brand.naranja" }}
                        onClick={() => {
                          navigate("/geocercas");
                          setExpanded(false);
                        }}
                      >
                        Geocercas
                      </ListItem>
                    )}
                    <ListItem
                      color={
                        location.pathname === "/mapaCamiones" && "brand.naranja"
                      }
                      _hover={{ color: "brand.naranja" }}
                      onClick={() => {
                        navigate("/mapaCamiones");
                        setExpanded(false);
                      }}
                    >
                      Mapa de camiones
                    </ListItem>
                  </List>
                </AccordionPanel>
              </AccordionItem>
            )}
          </Accordion>
        </VStack>
      </Box>
    </VStack>
  );
};

export default Navigate;
