import { Stack, HStack, Divider } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import Navigate from "./components/Navigate";
import MultiTaskBar from "./components/MultiTaskBar";

const Layout = (props) => {
  const {
    expanded,
    setExpanded,
    mobile,
    filter,
    setFilter,
    notificaciones,
    setNotificaciones,
  } = props;

  return (
    <HStack w="100vw">
      {mobile && (
        <Navigate
          mobile={mobile}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      )}
      <Stack
        bg="brand.fondos_secundarios"
        w="100%"
        h={"100vh"}
        overflowY={"auto"}
      >
        <MultiTaskBar
          mobile={mobile}
          filter={filter}
          setFilter={setFilter}
          notificaciones={notificaciones}
          setNotificaciones={setNotificaciones}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <Divider bg="blackAlpha.50" w={"100%"} />
        <Outlet expanded={expanded} />
      </Stack>
    </HStack>
  );
};

export default Layout;
